<template>
	<div
		class=""
	>
		<Search
			v-if="program.search"
			:program="program"
			:search="item_search"
			:option="item_search_option"

			@click="getData"
		></Search>

		<div
			class=""
		>
			<template
				v-if="items.length > 0"
			>

				<ul class="hide-pc">
					<!-- 주문 내역-->
					<li
						v-for="(item, index) in item_list"
						:key="item.uid"
						class=" bg-white mb-20 box box-shadow position-relative"
					>
						<!-- 주문일자 및 주문상세 이동 -->
						<div

							class="pa-10 justify-space-between "
							@click="toDetail(item)"
						>
							<span class="">{{ item.wDate | transDate }}</span>
							<v-icon class="">mdi mdi-arrow-right-bold-box-outline</v-icon>
						</div>

						<div
							class=""
						>
							<div
								v-if="false"
								class="justify-space-between under-line-dashed pa-10"
							>
								<span>
									<span
										class="box pa-5"
										:class="'color-' + item.o_status_color"
									>{{ item.o_status_name }}</span>
								</span>
								<span class="color-blue">{{ item.order_price | makeComma }} 원</span>
							</div>

							<ul
								class="pa-10"
							>
								<li
									v-for="odt in item.odt_list"
									:key="'odt_' + odt.uid"
									class="pa-10 box mb-10 radius-10"
								>
									<div class="under-line pb-5 justify-space-between">
										<button
											class="font-weight-bold"
											:class="'color-' + odt.order_status_color"
										>{{ odt.order_status_name }}</button>
										<button
											v-if="item.delivery_type != 'D002002' && odt.is_shipping && odt.pdt_div != 'ticket'"
											class="box prl-10 size-px-11 ml-5 color-blue"
											@click="toShipping(odt)"
										>배송조회 <v-icon small >mdi mdi-chevron-right</v-icon></button>
									</div>
									<div
										class="justify-space-between ptb-10 "
									>
										<span
											class="flex-1 pdt-img mr-10 flex-column justify-center "
											@click="toItem(odt.pdt_uid)"
										>
											<img
												:src="odt.pdt_img"
												@error="$bus.$emit('onErrorImage', $event)"
											/>
										</span>
										<div class="flex-3">
											<div class="under-line-dashed pb-5"><button
												@click="toItem(odt.pdt_uid)">{{ odt.pdt_name}}</button> <br/> <span class="color-gray size-em-09">선택 옵션: {{ odt.op_name }}</span></div>
											<div class="mt-5 justify-space-between items-center">
												<span class="font-weight-bold">{{ odt.odt_price | makeComma }} 원</span>
												<span class="size-em-09"> 수량: {{ odt.op_cnt }}개</span>
											</div>
										</div>
									</div>
									<div
										class="mt-10  under-line-dashed pb-10"
									>
										<button
											v-if="odt.is_cancel"
											class="box prl-10 size-px-11 bg-red "
											@click="isOdtUpdate(odt, odt_step_cancel, item.pg_code)"
										>주문취소 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

										<button
											v-if="odt.is_confirm"
											class="box prl-10 size-px-11 bg-green mr-5"
											@click="toOdtConfirm(odt)"
										>구매확정 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

										<button
											v-if="odt.is_return && (!item.is_purchase || item.is_purchase == '0')"
											class="box prl-10 size-px-11 bg-orange mr-5 "
											@click="toOdtReturn(odt)"
										>교환/반품<v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

									</div>
								</li>
							</ul>
							<div class="pa-10 top-line justify-space-between ">
								<span class="font-weight-bold" :class="'color-' + item.o_status_color">{{ item.o_status_name }}</span>
								<span class="font-weight-bold">결제 금액: {{ item.order_price | makeComma }} 원</span>
							</div>
							<div
								v-if="false"
								class="pa-10 text-right"
							>
								<button
									v-if="item.is_cancel"
									class="btn-danger size-px-12 prl-10"
									@click="viewCancel(item, index)"
								>결제 취소 요청 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
							</div>
						</div>
					</li>
				</ul>

				<table class="table hide-m">
					<colgroup>
						<col width="60px" />
						<col width="auto" />
						<col width="auto" />
						<col width="120px" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
					</colgroup>
					<thead>
						<tr>
							<th>No</th>
							<th>주문번호</th>
							<th>결제 금액</th>
							<th>결제 방식</th>
							<th>주문일시</th>
							<th colspan="2">주문 상품</th>
							<th>상품 금액</th>
							<th>수량</th>

							<th>진행 상태</th>
							<th>상세보기</th>
						</tr>
					</thead>
					<tbody>
						<template
							v-for="(item, index) in item_list"
						>
							<tr
								v-for="(odt, o_index) in item.odt_list"
								:key="'item_' + index + '_odt_' + o_index"
							>
								<td
									v-if="o_index == 0"
									:rowspan="item.odt_list.length"
								>{{ item.no }}</td>
								<td
									v-if="o_index == 0"
									:rowspan="item.odt_list.length"
									@click="toDetail(item)"
								>{{ item.order_num_new }}</td>
								<td
									v-if="o_index == 0"
									:rowspan="item.odt_list.length"
								>{{ item.order_price | makeComma }} 원</td>
								<td
									v-if="o_index == 0"
									:rowspan="item.odt_list.length"
								>
									{{ item.pay_div_name }}
								</td>
								<td
									v-if="o_index == 0"
									:rowspan="item.odt_list.length"
								>{{ item.wDate }}</td>
								<td
									@click="toItem(odt.pdt_uid)"
								>
									<img
										:src="odt.pdt_img"
										@error="$bus.$emit('onErrorImage', $event)"
										class="object-cover"
									/>
								</td>
								<td
									class="td-left"
								>
									<div class="under-line-dashed pb-5"><button
										@click="toItem(odt.pdt_uid)">{{ odt.pdt_name}}</button> <br/> <span class="color-gray size-em-09">선택 옵션: {{ odt.op_name }}</span></div>
								</td>
								<td>{{ odt.odt_price | makeComma }} 원</td>
								<td>{{ odt.op_cnt }}개</td>
								<td>
									<button
										class="font-weight-bold "
										:class="'color-' + odt.order_status_color"
									>{{ odt.order_status_name }}</button>

									<button
										v-if="item.delivery_type != 'D002002' && odt.is_shipping && odt.pdt_div != 'ticket'"
										class="pa-5-10 bg-blue mt-10"
										@click="toShipping(odt)"
									>배송조회 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

									<button
										v-if="odt.is_cancel && (!item.is_purchase || item.is_purchase == '0')"
										class="pa-5-10 bg-red mt-10"
										@click="isOdtUpdate(odt, odt_step_cancel, item.pg_code)"
									>주문취소 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

									<button
										v-if="odt.is_confirm"
										class="pa-5-10 bg-green mt-10"
										@click="toOdtConfirm(odt)"
									>구매확정 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>

									<button
										v-if="odt.is_return && (!item.is_purchase || item.is_purchase == '0')"
										class="pa-5-10 bg-orange mt-10"
										@click="toOdtReturn(odt)"
									>교환/반품<v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
								</td>
								<td>
									<button
										@click="toDetail(item)"
									><v-icon>mdi mdi-arrow-right-bold-box-outline</v-icon></button>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
				<Pagination
					:program="program"
					:align="'center'"
					:options="item_search"

					@click="getSearch"

					class="mt-auto pa-10"
				></Pagination>
			</template>
			<Empty
				v-else
			></Empty>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="is_modal = !is_modal"
		>
			<div
				slot="modal-bottom"
				class="justify-space-between"
			>
				<button
					class="btn-default flex-1 pa-20 size-px-16"
					@click="clearItem"
				>닫기</button>
				<button
					class="btn-primary flex-1 pa-20 size-px-16"
					@click="onCancel"
				>확인</button>
			</div>
		</Modal>


		<iframe
			v-if="(item_cancel.pay_div == 'hecto' || item_cancel.pay_div == 'hecto_tel' ) && is_on_cancel"
			:src="danal_url"
			class="full-height full-width"
			style="display: none"
		></iframe>
	</div>
</template>

<script>
	import Modal from "@/components/Modal";
	import Pagination from "../../components/Pagination";
	import Empty from "@/view/Layout/Empty";
	import Search from "@/view/Layout/Search";

	export default{
		name: 'OrderList'
		, components: {Search, Empty, Pagination, Modal}
		, props: ['user', 'codes', 'shop_info']
		,data: function() {
			return {
				program: {
					name: '주문내역'
					, top: false
					, title: true
					, bottom: true
					, top_line: true
					, search: true
				}
				,item_search: {
					type: this.$route.params.type
					, page: 1
					, list_cnt: 10
					, tCnt: 0
					, sDate: this.$date.getLastDate(7,'-')
					, eDate: this.$date.getToday('-')
				}
				, item_search_option: {
					sDate: true
					, eDate: true
					, is_keyword: true
					, is_cnt: true
				}
				,items: [

				]
				,is_modal: false
				,modal_option: {
					top: true
					,title: '주문취소'
					,content: '해당 주문상품을 취소하시겠습니까?'
					,bottom: true
				}
				,cancel_item: ''
				,item: {

				}
				,odt_step_confirm: 'step5'
				,odt_step_cancel: 'step21'
				,odt_step_cancel_confirm: 'step22'
				,odt_step_change: 'step31'
				,odt_step_return: 'step41'
				,item_cancel: {

				}
				,item_return: {

				}
				,item_confirm: {

				}
				,item_result: {

				}
				, is_on_cancel: false
				, base_url: ''
				, danal_url: ''
			}
		}

		,computed: {
			item_list: function(){

				let now = this.$date.getToday('-')
				return this.items.filter((item, index) => {
					item.is_cancel = true
					if(now != item.wDate.slice(0, 10)){
						item.is_cancel = false
					}
					item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index

					switch (item.o_status){
						default: case "1":
							item.o_status_name = "결제대기"
							item.o_status_color = "gray"
							break;

						case "2":
							item.o_status_name = "결제완료"
							item.o_status_color = "green"
							break
						case "3":
							item.o_status_name = "취소 요청"
							item.o_status_color = "orange"
							item.is_cancel = false
							break
						case "4":
							item.o_status_name = "결제취소"
							item.o_status_color = "red"
							item.is_cancel = false
							break;
						case "5":
							item.o_status_name = "부분취소요청"
							item.o_status_color = "orange"
							item.is_cancel = false
							break;
						case "6":
							item.o_status_name = "부분취소완료"
							item.o_status_color = "red"
							item.is_cancel = false
							break;
					}
					item.odt_list.filter((odt) => {
						odt.odt_price = Number(odt.pdt_price) + Number(odt.op_price)
						if(odt.pdt_img){
							odt.pdt_img = this.$pdt_img_url + odt.pdt_img
						}
						odt.is_shipping = true

						switch (odt.order_status){
							default: case 'step1':
								odt.is_shipping = false
								odt.is_cancel = true
								break
							case 'step2':
								odt.is_cancel = false
								odt.is_shipping = false

								item.is_cancel = false
								break
							case 'step3':

								item.is_cancel = false
								break
							case 'step4':
								odt.is_cancel = false
								odt.is_confirm = true
								odt.is_return = true

								item.is_cancel = false
								if(odt.pdt_div == 'ticket'){
									odt.order_status_name = '주문 완료 '
									odt.is_cancel = true
									odt.is_return = false
								}
								break
							case 'step5':

								item.is_cancel = false
								break
							case 'step21':
								odt.is_shipping = false

								item.is_step21 = false
								break
							case 'step22':
								odt.is_shipping = false

								//item.is_cancel = false
								break
							case 'step31':

								item.is_cancel = false
								break
							case 'step32':

								item.is_cancel = false
								break
							case 'step33':

								item.is_cancel = false
								break
							case 'step41':

								item.is_cancel = false
								break
							case 'step42':

								item.is_cancel = false
								break
							case 'step43':

								item.is_cancel = false
								break
						}
						if(now != item.wDate.slice(0, 10)){
							odt.is_cancel = false
						}
					})
					this.$codes.order_pay_div.filter( (code) => {
						if(code.code == item.pay_div){

							this.$codes.order_pay_div.filter( (code) => {
								if(code.code == item.pay_div){
									item.pay_div_name = code.name
								}
							}).pay_div_name = code.name
						}
					})
					return item
				})
			}
		}
		,methods: {
			getData: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'get'
						,url: 'order/getOrderList'
						,data: this.item_search
					})

					if(result.success){
						this.items = result.data.result
						this.$set(this.item_search, 'tCnt', result.data.tCnt)
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch(e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,toDetail: function(item){
				this.$router.push({ name: 'OrderDetail', params: {order_num_new: item.order_num_new}})
			}
			,viewCancel: function(item, index){
				this.is_modal = true
				item.index = index
				this.cancel_item = item

				// console.log(item)
			}
			, postOdtCancelConfirm: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postOdtUpdate'
						,data: {
							uid: this.item_cancel.uid
							, next_step: this.odt_step_cancel_confirm
						}
					})

					if(result.success){
						await this.getData()
						this.$bus.$emit('notify', { type: 'success', message: result.message})
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			, postCancelBillgate: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'billgate/postCancel'
						,data: {
							odt_uid: this.item_cancel.uid
						}
					})

					if(result.success){
						await this.postOdtCancelConfirm()
					}else{
						await this.getData()
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			, postCancelPaytus: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'paytus/postCancel'
						,data: {
							odt_uid: this.item_cancel.uid
						}
					})

					if(result.success){
						await this.postOdtCancelConfirm()
					}else{
						await this.getData()
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			, postCancelFirst: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'first/postCancel'
						,data: {
							odt_uid: this.item_cancel.uid
						}
					})

					if(result.success){
						await this.postOdtCancelConfirm()
					}else{
						await this.getData()
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			, postCancelAllat: async function(){
				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'allat/cancel'
						,data: {
							odt_uid: this.item_cancel.uid
						}
					})

					if(result.success){
						await this.postOdtCancelConfirm()
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			, postOdtCancel: async function(){
				this.$bus.$emit('on', true)
				this.is_modal = false
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postOdtUpdate'
						,data: {
							uid: this.item_cancel.uid
							,next_step: this.odt_step_cancel_confirm
						}
					})

					if(result.success){

						if(this.item_cancel.pg_code == 'allat') {
							await this.postCancelAllat()
						}else if(this.item_cancel.pg_code == 'first'){
							await this.postCancelFirst()
						}else if(this.item_cancel.pg_code == 'paytus'){
							await this.postCancelPaytus()
						}else if(this.item_cancel.pg_code == 'billgate'){
							await this.postCancelBillgate()
						}else{
							await this.getData()
							this.$bus.$emit('notify', { type: 'success', message: result.message})
						}
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.clearItem()
				}
			}
			,toOrderCancel: async function(){

				this.$bus.$emit('on', true)
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postOrderCancel'
						,data: this.cancel_item
					})

					if(result.success){
						this.$set(this.items[this.cancel_item.index], 'o_status', result.data.item.o_status)
						this.is_modal = false
						this.$bus.$emit('notify', { type: 'success', message: result.message})
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$emit('offLoad')
				}
			}
			,getTypeData: function(type){

				this.item_search.type = type
				this.item_search.page = 1
				this.getData()
			}
			,isCancel: function(odt){
				this.item_cancel = odt
			}
			,isReturn: function(odt){
				this.item_return = odt
			}
			,clearItem: function(){
				this.is_modal = false
				this.item = {}
				this.item_cancel = {}
				this.item_return = {}
				this.item_confirm = {}
				this.item_result = {}
				this.$bus.$emit('on', false)
			}
			,setReason: function(reason){
				this.$set(this.item_return, 'reason', reason)
			}
			,setCancelFile: function(e){
				console.log(e)
				let file = e.data.files[0]

				this.$set(this.item_return, 'img', file)
			}
			,isConfirm: function(odt){
				this.item_confirm = odt
				this.$router.push({ name: 'OdtConfirm', params: {odt_uid: odt.uid}})
			}
			,setNotify: function({ type, message }){
				this.$bus.$emit('notify', { type: type, message: message})
			}
			,confirm: function(){
				this.$set(this.item_confirm, 'order_status', 'step5')
				this.clearItem()
			}
			,cancel: function(step) {
				this.$set(this.item_confirm, 'order_status', step)
				this.clearItem()
			}
			,onLoading: function(){
				this.$bus.$emit('on', true)
			}
			,offLoading: function(){
				this.$bus.$emit('on', false)
			}
			,toShipping: function(odt){
				let code = this.codes.G000.items
				let url = ''
				for(let i = 0; i < code.length; i++){
					if(code[i].total_code == odt.shipping_name){
						url = code[i].code_value + odt.shipping_num
						break
					}
				}
				// console.log('toShipping', url)
				window.open(url, 'shipping')
			}
			,getSearch: function(page){
				this.item_search.page = page
				this.getData()
			}
			,isOdtUpdate: function(odt, step, pg_code){
				this.is_modal = true
				this.modal_option.type = step
				this.item_cancel = odt
				this.$set(this.item_cancel, 'pg_code', pg_code)
			}
			,toOdtConfirm: function(odt){
				this.$router.push({ name: 'OdtConfirm', params: { odt_uid: odt.uid }})
			}
			,toOdtReturn: function(odt){
				this.$router.push({ name: 'OdtReturn', params: { odt_uid: odt.uid }})
			}
			, toItem: function(idx){
				this.$bus.$emit('to', { name: 'ProductDetail', params: { idx: idx }, not_query: true})
			}
			, cancelResult: function(e){
				// console.log('chargeResult', e)
				try {
					let domains = process.env.VUE_APP_DOMAIN.split('|')
					let t = false
					for(let i = 0; i < domains.length; i++){
						if(e.origin.indexOf(domains[i]) > -1){
							t = true
						}
					}

					if(t){

						let data = e?.data
						if(data && typeof data == 'string') {
							data = JSON.parse(data)
							// console.log(data)
							if(data.type == 'hecto' || data.type == 'hecto_tel') {
								// console.log('cancelResult', data)
								if (data.result) {
									if(this.item_cancel.uid){
										this.postOdtCancel()
									}
								} else {
									throw data.message
								}
							}
						}
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
					this.is_on_charge = false
					this.is_on_payment = false
				}finally {
					this.is_on_confirm = false
				}
			}
			, onEvent: function(){
				window.removeEventListener('message', this.cancelResult)
				setTimeout( () => {
					window.addEventListener('message', this.cancelResult)
				}, 100)
			}

			, getHectoCancel: async function(item){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: 'order/getHectoCancel'
						, data: item
					})

					if(result.success){
						this.danal_url = this.base_url + result.data + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')
						this.is_on_cancel = true
						this.item_cancel = item
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, onCancel: function(){
				let item = this.item_cancel
				// console.log('onCancel', item)

				if(this.item_cancel.pay_div == 'hecto' || this.item_cancel.pay_div == 'hecto_tel'){
					this.getHectoCancel(item)
				}else{
					this.postOdtCancel()
				}
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
			this.item_search.type = this.$route.params.type
			this.base_url = this.$danal_url
			this.onEvent()
		}
		,watch: {
			$route(){
			}
		}
	}
</script>
<style>
	.pdt-img img { width: 100% }

	.tab .on { background-color: #333; color: #bbb}
</style>